import { graphql } from "gatsby"
import React from "react"
export default function Index() {
  return <div></div>
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
